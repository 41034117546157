import standard from '../assets/img/standard.svg';
import light from '../assets/img/light.svg';
import check from '../assets/img/check.svg';
import dark from '../assets/img/dark.svg';

const icons = {
  'standard': standard,
  'light': light,
  'dark': dark
};

const getActiveVariant = (variants, servicePlanId) => {
  const activeVariantFromList = variants.find((variant) => (variant.plan_id === servicePlanId));

  if (servicePlanId && activeVariantFromList) return activeVariantFromList;

  return variants.find((variant) => variant.name === 'standard');
};

const getVariants = async (crmPlanId, WIDGET_API_URL) => {
  const response = await fetch(`${WIDGET_API_URL}/api/crm/plans/widget/variant/${crmPlanId}/from_crm_plan`);
  return await response.json();
};

export const createVariantPicker = async (crmPlanId, servicePlanId, WIDGET_API_URL) => {
  const variants = await getVariants(crmPlanId, WIDGET_API_URL);

  if (!variants.find((variant) => variant.name === 'standard')) {
    variants.push({
      name: 'standard',
      plan_id: null
    })
  };

  const activeVariant = getActiveVariant(variants, servicePlanId);

  if (!activeVariant || variants.length < 2) return;

  const container = document.querySelector('.stylepicker-list');
  const stylepicker = document.querySelector('.stylepicker');

  container.innerHTML = '';

  variants.forEach((variant) => {
    const active = activeVariant.name === variant.name;
    const element = document.createElement('button');

    element.classList.add('stylepicker-button');

    if (active) {
      element.classList.add('stylepicker-button--active');
    }

    element.innerHTML = `
      <img class="stylepicker-button__icon" src="${icons[variant.name]}" />
      <img class="stylepicker-button__check" src="${check}" />
    `;

    element.setAttribute('title', variant.name.capitalize());

    element.addEventListener('click', () => {
      const url = new URL(window.location.origin);

      const { searchParams } = new URL(document.location);
      searchParams.forEach((item, key) => {
        if (key !== 'servicePlanId') url.searchParams.set(key, item);
      });

      if (variant.plan_id) {
        url.searchParams.set('servicePlanId', variant.plan_id);
      }

      window.location.replace(url.href);
    });

    container.appendChild(element)
  });

  const update = () => {
    window.requestAnimationFrame(update);

    const map = document.querySelector('.widget-tab__panorama-map');
    const isScaled = map?.classList.contains('widget-tab__panorama-map--scaled');
    const isHidden = map?.classList.contains('widget-tab__panorama-map--hidden');

    if (!map || isScaled || isHidden) {
      return stylepicker.classList.remove('stylepicker--visible');
    }

    const { left, top } = map.getBoundingClientRect();

    stylepicker.style.left = `${left}px`
    stylepicker.style.top = `${top}px`

    stylepicker.classList.add('stylepicker--visible');
  };

  update();
};
